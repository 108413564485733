import { useEffect } from 'react'
import { navigate } from 'gatsby'

// redirect to act_papers page
export default function redir() {
  useEffect(() => {
    navigate('/resources/act_papers/', { replace: true })
  }, [])

  return null
}
